import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBqVEE2m5tSXQJx6yHD9YgIavGaE3GzBvY",
  authDomain: "wedding-rsvp-4de82.firebaseapp.com",
  projectId: "wedding-rsvp-4de82",
  storageBucket: "wedding-rsvp-4de82.appspot.com",
  messagingSenderId: "378801383379",
  appId: "1:378801383379:web:d453bd3379093bd4e2f126",
  measurementId: "G-M372HF5DMG",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
