import React from "react";
import calender from "../assets/img/calender.svg";
import direction from "../assets/img/direction.svg";
const Footer = () => {
  return (
    <div className="flex flex-col sm:flex-row gap-6 justify-center items-center sm:justify-between mt-8">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.google.com/calendar/render?action=TEMPLATE&text=Wedding+of+Dhanushki+%26+Sashen&details=Poruwa+Ceremony+at+09%3A34am&location=Pegasus+Reef+Hotel%2C+Wattala&dates=20220525T043000Z%2F20220525T090000Z"
        className="flex gap-2 items-center"
      >
        <img src={calender} alt="" />
        <span className="text-[#C67F85]">Add to Google Calendar</span>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.google.com/maps/dir//Pegasus%20Reef%20Hotel"
        className="flex gap-2 items-center"
      >
        <img src={direction} alt="" />
        <span className="text-[#C67F85]">Direction to Hotel</span>
      </a>
    </div>
  );
};

export default Footer;
