import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LeafBranch from "../assets/img/leaf-branch.svg";
import firebase from "../Firebase";
import RadioLabel from "./RadioLabel";
import { Spinner } from "./Spinner";
import TextArea from "./TextArea";

const Card = () => {
  let params = useParams();
  const [guest, setGuest] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rsvpStatus, setRSVPStatus] = useState(null);
  const [isDrink, setIsDrink] = useState(null);
  const [message, setMesssage] = useState("");
  const [status, setStatus] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);

  const guestsRef = firebase
    .firestore()
    .collection("guests")
    .doc(params.guestId);

  const getData = () => {
    setIsLoading(true);
    guestsRef
      .get()
      .then((snapshot) => {
        setGuest(snapshot.data());
        setRSVPStatus(snapshot.data().rsvp);
        switch (snapshot.data().rsvp) {
          case "1":
            setStatus("COMING");
            break;

          case "2":
            setStatus("NOTCOMING");
            break;
          default:
            setStatus(null);
            break;
        }
        if (snapshot.data().drink) {
          setIsDrink(snapshot.data().drink);
        }
        setMesssage(snapshot.data().message);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setGuest(undefined);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []); //eslint-disable-line

  const handleUpdate = () => {
    if (rsvpStatus !== 0) {
      guestsRef
        .update({
          rsvp: rsvpStatus,
          message: message,
          drink: isDrink,
          responded_time: new Date(),
        })
        .then(() => {
          console.log("Updated successfully!");
          console.log(rsvpStatus);
          rsvpStatus === "1" ? setStatus("COMING") : setStatus("NOTCOMING");
        })
        .catch((e) => console.log(e));
    } else {
      setErrorMsg(true);
      setTimeout(() => {
        setErrorMsg(false);
      }, 2000);
    }
  };

  const handleChange = (event) => {
    setRSVPStatus(event.target.value);
  };
  const handleDrink = (event) => {
    setIsDrink(event.target.value);
  };
  const handleMessage = (event) => {
    setMesssage(event.target.value);
  };
  return (
    <div
      className={`bg-white rounded-2xl mb-4 text-center  mx-auto mt-10 p-8 drop-shadow-card relative min-h-[408px]`}
    >
      <img
        src={LeafBranch}
        alt=""
        className="absolute top-0 left-0 right-0 mx-auto -z-10"
      />

      {!isLoading ? (
        <>
          <>
            {guest !== undefined ? (
              <>
                {status == null ? (
                  <div>
                    <h1 className="text-3xl tracking-[0.2em] text-[#231414] font-semibold mb-2">
                      RSVP
                    </h1>
                    <p className="text-[#7B7B7B] tracking-wide">
                      Kindly respond by May 18, 2022. We look forward to
                      celebrate with you!
                    </p>
                    <h2 className="text-3xl font-adelia my-8 text-[#231414] leading-[2]">
                      {`${guest.title} ${guest.name}`}
                    </h2>
                    <p className="text-[#7B7B7B]  uppercase tracking-[0.205em] mb-8">
                      Are you attending?
                    </p>
                    <div className="flex flex-col gap-4 sm:flex-row justify-between max-w-md mx-auto">
                      <RadioLabel
                        label="Accept with Pleasure!"
                        id="decline"
                        name="rsvp"
                        checked={rsvpStatus === "1"}
                        value={1}
                        onChange={handleChange}
                      />
                      <RadioLabel
                        label=" Decline with Regret!"
                        id="accept"
                        name="rsvp"
                        checked={rsvpStatus === "2"}
                        value={2}
                        onChange={handleChange}
                      />
                    </div>
                    {(rsvpStatus === "1" || rsvpStatus === 1) && (
                      <>
                        <p className="text-[#7B7B7B]  uppercase tracking-[0.205em] mb-8 mt-4">
                          Alcohol Preference 🍻
                        </p>
                        <div className="flex flex-col gap-4 sm:flex-row justify-between max-w-md mx-auto">
                          <RadioLabel
                            label="Beer"
                            id="drink-beer"
                            name="drink"
                            checked={isDrink === "beer"}
                            value={"beer"}
                            onChange={handleDrink}
                          />
                          <RadioLabel
                            label="Hard Liquor"
                            id="drink-liquor"
                            name="drink"
                            checked={isDrink === "liquor"}
                            value={"liquor"}
                            onChange={handleDrink}
                          />
                          <RadioLabel
                            label="None"
                            id="drink-no"
                            name="drink"
                            checked={isDrink === "none"}
                            value={"none"}
                            onChange={handleDrink}
                          />
                        </div>
                      </>
                    )}

                    {errorMsg && (
                      <p className="text-red-700 text-xs mt-2">
                        Please choose your RSVP status
                      </p>
                    )}
                    <TextArea
                      placeholder="Write a Message / Wish"
                      onChange={handleMessage}
                      value={message}
                    />
                    <button
                      className="bg-[#EAB2B2] hover:bg-[#C67F85] transition text-white uppercase px-8 py-2 rounded-full tracking-wider"
                      onClick={handleUpdate}
                    >
                      Confirm
                    </button>
                  </div>
                ) : (
                  <div>
                    <h1 className="text-3xl tracking-[0.2em] text-[#231414] font-semibold mb-2 uppercase mt-20">
                      {status === "COMING" ? "Thank you!" : "oh!"}
                    </h1>
                    <p className="text-[#7B7B7B] tracking-wide">
                      {status === "COMING"
                        ? "We look forward to see you there!"
                        : "It's okay. Thanks for the response."}
                    </p>

                    <button
                      className="text-[#C67F85] mt-20 underline-offset-2 underline"
                      onClick={() => setStatus(null)}
                    >
                      Do you want to change your RSVP?
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <h2 className="text-3xl my-8 text-[#231414] leading-[2]">
                  Invalid Guest ID
                </h2>
              </>
            )}
          </>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Card;
