import Card from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";

export const Guest = () => {
  return (
    <div className="p-4 max-w-3xl mx-auto">
      <Header />
      <Card></Card>
      <Footer />
    </div>
  );
};
