import { Routes, Route } from "react-router-dom";
import { Guest } from "./pages/Guest";
import Home from "./pages/Home";
import List from "./pages/List";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact={true} element={<Home />}></Route>
        <Route path="/guest=:guestId" element={<Guest />} />
        <Route path="/list" element={<List />} />
      </Routes>
    </>
  );
}

export default App;
