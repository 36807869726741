import Footer from "../components/Footer";
import Header from "../components/Header";
import firebase from "../Firebase";

function Home() {
  const createRecord = (portfolio) => {
    const guestsRef = firebase.firestore().collection("guests").doc();
    guestsRef.set(portfolio);
  };

  const isEdit = false;

  const data = [
    {
      name: "Udaya Sathya",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Chandula Jithmi",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Kaveesha Dilshani",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Dinushi Herath",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Nawanjana Maheepala",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Kaushalya Gajanayake",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Navoda Kadurugamuwa",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Madri Madawala",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Rifnaz Rafeek",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Uthpala Kaushalya",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Melanja Shanuka",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Nishath Yapa",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Roshan Ragel",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Anjana Senanayake",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Tharuka Bandara",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Shanaka Munasinghe",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Kasun Vimukthi",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Puwasuru Ihalagedara",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Harsha De Silva",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Chathurangi Edussuriya",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Anuradha Wijewickrama",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Sandali Lokuge",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Sashini Alles",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Tashini Naveendri",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Rashmi Brandigampola",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Wimarsha Fernando",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Ishanka Awarjana",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Sunari Umayangana",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Chandima Jayamani",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Nipuni Hewage",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Anjana Dilshan",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Dhanusha Nadeen",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
    {
      name: "Thanuri Dinushika",
      rsvp: 0,
      message: "",
      count: "",
      responded_time: "",
      belongs_to: "dhanushki",
    },
  ];

  const generateAll = async () => {
    data.forEach((person) => {
      createRecord(person);
    });
  };

  return (
    <div className="h-screen grid place-content-center">
      <div className=" max-w-3xl mx-auto mt-[-5rem] p-1">
        <Header />
        <p className="uppercase text-center mt-4 tracking-widest">
          At Pegasus Reef Hotel, Wattala
        </p>
        <Footer />
      </div>
      {isEdit && <button onClick={generateAll}>Add guest</button>}
    </div>
  );
}

export default Home;
