import React from "react";

const RadioLabel = ({ onChange, value, id, name, label, checked }) => {
  return (
    <div class="flex items-center mr-4 mb-4">
      <input
        type="radio"
        id={id}
        name={name}
        className="mr-2 tracking-wide accent-[#b07676] hidden"
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={id} className="flex items-center cursor-pointer">
        <span class="w-4 h-4 inline-block mr-2 rounded-full border border-slate-300"></span>
        {label}
      </label>
    </div>
  );
};

export default RadioLabel;
