import React from "react";

const TextArea = ({ value, onChange, name, placeholder }) => {
  return (
    <textarea
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      className="w-full border border-slate-200 border-solid px-4 py-2 rounded-lg font-sans my-8"
    >
      {value}
    </textarea>
  );
};

export default TextArea;
