import React from "react";
import logo from "../assets/img/logo.svg";
const Header = () => {
  return (
    <div className="text-center mt-10 sm:mt-20 ">
      <h4 className="text-[#231414] uppercase tracking-[0.205em] text-center text-xl mb-4">
        The wedding of
      </h4>
      <img src={logo} alt="Dhanushki & Sashen" className="mx-auto" />
    </div>
  );
};

export default Header;
