import React, { useState, useEffect } from "react";
import firebase from "../Firebase";

const List = () => {
  const [guests, setGuests] = useState([]);
  useEffect(() => {
    // const guestsRef = firebase.firestore().collection("guests");
    const db = firebase.firestore();
    return db.collection("guests").onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
      console.log(postData); // <------
      setGuests(postData);
    });
  }, []);

  const getDrinkEmoji = (drink) => {
    switch (drink) {
      case "beer":
        return "🍺";
      case "liquor":
        return "🍾";
      default:
        return drink;
    }
  };

  return (
    <div className="p-5">
      <table className="font-sans">
        <thead>
          <tr className="text-left border-b border-b-slate-300">
            <th>ID</th>
            <th>Title</th>
            <th>Name</th>
            <th>Belongs To</th>
            <th>RSVP</th>
            <th>Drink</th>
            <th width="30%">Message</th>
            {/* <th>Link</th> */}
          </tr>
        </thead>
        <tbody>
          {guests.length > 0 &&
            guests
              // .filter((guest) => guest.belongs_to === "dhanushki")
              .map((guest) => (
                <tr className="border-b border-b-red-100 h-10">
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://rsvp.sashen.me/guest=${guest.id}`}
                    >
                      {guest.id}
                    </a>
                  </td>
                  <td>{guest.title}</td>
                  <td>{guest.name}</td>
                  <td>
                    {guest.belongs_to === "dhanushki" ? "Dhanushki" : "Sashen"}
                  </td>
                  <td>
                    {guest.rsvp === 0 ? (
                      "Not responded"
                    ) : guest.rsvp === "1" ? (
                      <span className="text-green-600">Yes</span>
                    ) : (
                      <span className="text-red-600">No</span>
                    )}
                  </td>
                  <td>{getDrinkEmoji(guest.drink)}</td>
                  <td>{guest.message}</td>
                  {/* <td>{`https://rsvp.sashen.me/guest=${guest.id}`}</td> */}
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
